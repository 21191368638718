<template>
  <div class="wrapper wrapper-content basic list">
    <div class="ibox">
      <div class="ibox-content">
        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead>
              <tr>
                <th :colspan="fields.length">
                  <Pills :items="groups" :value="groupId" @change="onChangeGroup" v-if="groups.length > 0" />
                <SearchInput @input="keyword = $event" :value="keyword" @submit="onSearch(keyword, filter)" />
                  <div class="search-input" style="margin-right: 0.5rem">
                    <select v-model="filter" class="form-control" >
                      <option value="all">전체</option>
                      <option value="id">ID</option>
                      <option value="date">날짜(ex.202205)</option>
                    </select>
                  </div>
                </th>
              </tr>

              <tr class="info">
                <th :colspan="fields.length">
                  <div class="info">
                    Showing {{ page }} to {{ Math.max(1, parseInt(total / size)) }} of
                    <strong>{{ total }}</strong> entries
                  </div>

                  <div class="utils">
                    <button v-if="useExport" class="btn btn-util" @click="toggleUtil('excel')">
                      <svg-icon name="excel"></svg-icon> Export to Excel
                    </button>
                  </div>
                </th>
              </tr>

              <tr class="export" v-if="showExport">
                <th :colspan="fields.length">
                  <div class="export-form">
                    <div class="form-group">
                      <div class="select">
                        <select v-model="exportDays" class="form-control" @change="onChangeExport">
                          <option :value="0">전체</option>
                          <option :value="1">지난 1일</option>
                          <option :value="7">지난 7일</option>
                          <option :value="30">지난 30일</option>
                          <option :value="90">지난 90일</option>
                          <option :value="-1">직접선택</option>
                        </select>
                      </div>

                      <div class="input">
                        <datetime
                          v-model="exportDateStart"
                          class="form-control"
                          type="date"
                          zone="Asia/Seoul"
                          name="export_date_start"
                          :week-start="0"
                          placeholder="Start Date"
                          :disabled="exportDays !== -1"
                        />
                        <div class="divine"></div>
                        <datetime
                          v-model="exportDateEnd"
                          class="form-control"
                          type="date"
                          zone="Asia/Seoul"
                          name="export_date_end"
                          :week-start="0"
                          placeholder="End Date"
                          :disabled="exportDays !== -1"
                        />
                      </div>

                      <span class="input-group-append">
                        <button type="button" class="btn btn-submit">
                          <export-excel
                            :name="exportName"
                            :export-fields="exportFields"
                            :data="exportItems"
                            :before-generate="loadExport"
                            >Export</export-excel
                          >
                        </button>
                      </span>
                    </div>
                  </div>
                </th>
              </tr>

              <tr class="fields">
                <template v-for="field in fields">
                  <th
                    scope="col"
                    :key="field.key + '.checked'"
                    v-if="groupId !== '' && field.type === 'checked'"
                    class="checkbox"
                  >
                    <input-checkbox
                      v-model="checkedAll"
                      name="checked[]"
                      expected="all"
                      indeterminated="half"
                      :ripple="true"
                      @click="onClickCheckedAll"
                    ></input-checkbox>
                  </th>
                  <th scope="col" :key="field.key" v-if="field.type !== 'checked'">{{ field.label }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in items" :key="i">
                <template v-for="field in fields">
                  <template v-if="groupId !== '' && visible(field, 'checked')">
                    <td :key="field.key + '.checked'" class="checkbox">
                      <input-checkbox
                        v-model="checked[row.uid]"
                        name="checked[]"
                        :expected="true"
                        :ripple="true"
                        @click="onClickCheck"
                      ></input-checkbox>
                    </td>
                  </template>

                  <template v-if="visible(field, 'uid')">
                    <td :key="field.key">{{ row[field.key] }}</td>
                  </template>

                  <template v-if="visible(field, 'datetime')">
                    <td :key="field.key">{{ fieldValue(field, row) }}</td>
                  </template>

                  <template v-if="visible(field, '*')">
                    <td :key="field.key">
                      <FilterValue
                        :name="field.key"
                        filter="all"
                        :keyword="keyword"
                        :value="fieldValue(field, row)"
                      ></FilterValue>
                    </td>
                  </template>

                  <template v-if="visible(field, 'resource')">
                    <td :key="field.key">
                      <progressive-background :src="getResource(field, row)" :aspect-ratio="1" class="thumbnail" />
                    </td>
                    <!-- <td><a :href="getResource(field, row)" target="_resource">DOWNLOAD</a><br />({{row['filetype']}})</td> -->
                  </template>

                  <template v-if="visible(field, 'action')">
                    <td :key="field.key" class="actions">
                      <button
                        v-for="action in field.actions"
                        :key="action.action"
                        class="btn btn-xs"
                        :class="getAction(action).class"
                        @click="getAction(action).onAction(row, { $router, $store, model })"
                      >
                        {{ getAction(action).label }}
                      </button>
                    </td>
                  </template>
                </template>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td :colspan="fields.length" class="text-left">
                  <div class="actions" v-if="groupId != ''">
                    <div class="left">
                      <!-- <button class="btn btn-xs btn-primary" v-if="groupId === 'NEW'" :disabled="checkedIds.length === 0" @click="onClickBatch('REQUEST')">일괄정산 요청</button> -->
                      <button
                        class="btn btn-xs btn-warning"
                        v-if="groupId === 'NEW'"
                        @click="onClickBatch('BATCH_NEW')"
                      >
                        일괄정산서 발행
                      </button>
                      <button
                        class="btn btn-xs btn-danger"
                        v-if="groupId === 'NEW'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('DELETE')"
                      >
                        일괄정산 삭제
                      </button>
                      <button
                        class="btn btn-xs btn-success"
                        v-if="groupId === 'PROGRESS'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('SETTLE')"
                      >
                        일괄정산 완료
                      </button>
                      <button
                        class="btn btn-xs btn-success"
                        v-if="groupId === 'PROGRESS'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('MOVETONEW')"
                      >
                        상태복원
                      </button>
                      <button
                        class="btn btn-xs btn-success"
                        v-if="groupId === 'SETTLED'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('MOVETOPROGRESS')"
                      >
                        상태복원
                      </button>
                      <button
                        class="btn btn-xs btn-warning"
                        v-if="groupId === 'PROGRESS'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('CANCEL')"
                      >
                        일괄정산 취소
                      </button>
                      <button
                        class="btn btn-xs btn-primary"
                        v-if="groupId === 'NEW'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('SETTLEMENT')"
                      >
                        일괄 정산서 발송
                      </button>
                      <button
                        class="btn btn-xs btn-primary"
                        v-if="groupId === 'SETTLED'"
                        :disabled="checkedIds.length === 0"
                        @click="onClickBatch('COMPLETE')"
                      >
                        일괄 알림톡 발송
                      </button>
                    </div>
                  </div>

                  <Pagination :page="page" :size="size" :total="total" @page="onPage" />

                  <div class="actions">
                    <div class="left">
                      <button type="button" v-if="groupId === 'PROGRESS'" class="btn btn-xs btn-info">
                        <export-excel
                          :name="requestBulkName"
                          :export-fields="requestBulkFields"
                          :data="requestBulkItems"
                          :before-generate="loadRequestBulk"
                          >대량이체 엑셀 다운로드</export-excel
                        >
                      </button>
                    </div>
                    <div class="right">
                      <button
                        v-for="action in actions"
                        :key="action.action"
                        class="btn btn-xs"
                        :class="getAction(action).class"
                        @click="getAction(action).onAction(row, { $router, $store, model })"
                      >
                        <fa-icon :icon="getAction(action).icon" v-if="getAction(action).icon" />
                        {{ getAction(action).label }}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pills from '@/components/elements/Pills';
import Pagination from '@/components/elements/Pagination';
import SearchInput from '@/components/utils/SearchInput';
import FilterValue from '@/components/utils/FilterValue';
import utils from '@/utils';

export default {
  data() {
    return {
      page: 1,
      size: 20,
      total: 0,
      items: [],
      fields: [],
      actions: [],

      groupId: '',
      groups: [],

      checkedAll: 'none',
      checked: {},

      filter: '',
      keyword: '',

      showExport: false,
      exportItems: [],
      exportDays: 0,
      exportDateStart: '',
      exportDateEnd: '',

      requestBulkItems: [],
    };
  },
  created() {
    this.model = this.$route.meta.model;

    this.$data.keyword = this.$route.query.keyword || '';
    this.$data.page = (this.$route.query.page && parseInt(this.$route.query.page)) || this.page;
    this.$data.size = (this.$route.query.size && parseInt(this.$route.query.size)) || this.size;

    this.$data.groups = this.model.groups || [];

    this.$data.filter = this.$route.query.filter || 'all'
    this.$data.fields = this.model.fields.filter((field) => field.listing && field);
    this.$data.actions = this.model.actions.list || [];

    this.$data.groupId =
      this.$route.query.group !== undefined
        ? this.$route.query.group
        : (this.model && this.model.getters && this.model.getters.defaultGroup) || '';

    if (this.$data.groupId === 'SETTLED') {
      this.$data.fields = this.model.fields.filter((field) => (field.listing || field.complete_talk) && field);
    }

    if (this.$data.groupId === 'NEW') {
      this.$data.fields = this.model.fields.filter((field) => (field.listing || field.settlement_talk) && field);
    }
  },
  mounted() {
    this.loadData();
  },
  computed: {
    useExport() {
      return this.model.types.export ? true : false;
    },
    exportName() {
      return `${this.model.name}.${this.$moment().format('YYYYMMDD')}.xls`;
    },
    exportFields() {
      if (this.model.types.export) {
        return this.model.types.export.fields;
      }
      return {};
    },
    requestBulkName() {
      return `정산용대량이체.${this.$moment().format('YYYYMMDD')}.xls`;
    },
    requestBulkFields() {
      if (this.model.types.requestBulk) {
        return this.model.types.requestBulk.fields;
      }
      return {};
    },
    checkedIds() {
      let ids = [];
      for (var uid in this.checked) {
        if (this.checked[uid]) {
          ids.push(parseInt(uid));
        }
      }
      return ids;
    },
  },
  methods: {
    loadData() {
      this.$data.loading = true;
      this.$store
        .dispatch(this.model.types.list.action, {
          page: this.page,
          size: this.size,
          group: this.groupId,
          filter: this.filter,
          keyword: this.keyword,
        })
        .then((data) => {
          let { items, total } = data;

          this.$data.items = items;
          this.$data.total = total;

          let checked = {};

          this._.forEach(items, (item) => {
            if (item.status !== 'CANCELED') {
              checked[item.uid] = false;
            }
          });

          this.$data.checked = checked;
          this.$data.checkedAll = 'none';
        })
        .catch((e) => {
          this.$toast.error(e.message);
        })
        .finally(() => {
          this.$data.loading = false;
        });
    },

    loadExport() {
      let params = {
        group: this.groupId,
        date_start: this.exportDateStart,
        date_end: this.exportDateEnd,
      };

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(this.model.types.export.action, params)
          .then((data) => {
            let { items, fields } = data;

            if (items.length === 0) {
              this.$toast.error('추출할 데이타가 없습니다.');
            }

            this.$data.exportItems = items;
            resolve(data);
          })
          .catch((e) => {
            this.$toast.error(e.message);
            reject(e);
          });
      });
    },

    loadRequestBulk() {
      let params = {};

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(this.model.types.requestBulk.action, params)
          .then((data) => {
            let { items, fields } = data;

            if (items.length === 0) {
              this.$toast.error('추출할 데이타가 없습니다.');
            }

            this.$data.requestBulkItems = items;
            resolve(data);
          })
          .catch((e) => {
            this.$toast.error(e.message);
            reject(e);
          });
      });
    },

    requestData(options = {}) {
      let { page, size, keyword } = options;
      this.$data.page = page || this.page;
      this.$data.size = size || this.size;
      this.$data.keyword = keyword || this.keyword;
      this.$data.filter = this.filter;

      this.$router.replace({
        query: {
          page: this.page,
          size: this.size,
          group: this.groupId,
          filter: this.filter,
          keyword: this.keyword,
        },
      });
    },

    visible(field, type) {
      if (field.type === type) {
        return true;
      }

      if (type === '*') {
        return !['uid', 'checked', 'datetime', 'resource', 'action'].includes(field.type);
      }

      return false;
    },

    getAction(action) {
      if (action === 'create') {
        return {
          action: 'create',
          class: 'btn-danger',
          icon: 'edit',
          label: '생성',
          onAction: this.onClickCreate,
        };
      } else if (action === 'edit') {
        return {
          action: 'edit',
          class: 'btn-success',
          label: '수정',
          onAction: this.onClickEdit,
        };
      } else if (action === 'delete') {
        return {
          action: 'delete',
          class: 'btn-danger',
          label: '삭제',
          onAction: this.onClickDelete,
        };
      } else if (action === 'view') {
        return {
          action: 'view',
          class: 'btn-info',
          label: '보기',
          onAction: this.onClickView,
        };
      }

      return action;
    },

    getResource(field, row) {
      if (field.alias) {
        return this.$utils.value(row, field.alias);
      }

      return row[field.key];
    },

    getDeleteMessage(row) {
      return (
        (this.model.types.delete && this.model.types.delete.message && this.model.types.delete.message(row)) ||
        `${row.uid}(을)를 삭제하시겠습니까?`
      );
    },

    onPage({ page }) {
      this.loadData({ page });
    },

    onSearch( keyword, filter ) {
      let options = {
        page: 1,
        keyword,
        filter
      }

      this.requestData(options)
      this.page = 1;
    },

    onPage({ page }) {
      this.requestData({ page });
    },

    onChangeGroup({ label, value }) {
      this.$data.groupId = value;
      this.requestData({
        page: 1,
      });
    },

    onChangeExport(e) {
      let exportDays = parseInt(e.target.value);
      if (exportDays > 0) {
        this.$data.exportDateStart = this.$moment()
          .subtract(exportDays - 1, 'days')
          .format('YYYY-MM-DD');
        this.$data.exportDateEnd = this.$moment().format('YYYY-MM-DD');
      } else {
        this.$data.exportDateStart = '';
        this.$data.exportDateEnd = '';
      }
    },

    getBatchCommandLabel(command) {
      let label = '';

      if (command === 'DELETE') {
        label = '일괄정산 삭제';
      } else if (command === 'SETTLE') {
        label = '일괄정산 완료';
      } else if (command === 'CANCEL') {
        label = '일괄정산 취소';
      } else if (command === 'SETTLEMENT') {
        label = '일괄 정산서 발송';
      } else if (command === 'SETTLEMENT') {
        label = '일괄 정산서 발송';
      } else if (command === 'COMPLETE') {
        label = '일괄 알림톡 발송';
      } else if (command === 'BATCH_NEW') {
        label = '일괄 정산서 생산'
      } else {
        throw new Error('UNKNOWN_COMMAND');
      }

      return label;
    },

    onClickBatch(command) {
      const label = this.getBatchCommandLabel(command);

      this.$dialog
        .confirm(`${label} 를(을) 실행할까요?`, {
          okText: '예',
          cancelText: '아니오',
        })
        .then((dialog) => {
          this.requestBatch(command);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    requestBatch(command) {
      if (command === 'REQUEST') {
        let params = { ids: this.checkedIds };

        this.$store
          .dispatch(this.model.types.batchRequest.action, params)
          .then((data) => {
            let { success, fail } = data;
            if (success > 0) {
              this.$toast.success(`${success}건이 일괄정산 요청 되었습니다.`);
            }
            if (fail > 0) {
              this.$toast.error(`${fail}건이 정산요청 되지 못했습니다.`);
            }
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          });
      } else if (command === 'BATCH_NEW') {
        // 정산서 일괄 생성, 요청
        const params = {
          expenses_per_order: 0,
          expenses_per_cancel: 0,
          profit_rate: 80,
          exchange_rate: 1100,
          expenses_etc: 0
        }
        this.$store
          .dispatch(this.model.types.batchNew.action, params)
          .then((data) => {
            let { success, fail } = data;
            if (success > 0) {
              this.$toast.success(`${success}개의 정산서가 생성되었습니다.`);
              this.$dialog
                .confirm(`${success}개의 정산서가 생성되었습니다.`, {
                  cancelText: '닫기',
                });
            }
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          })
      } else if (command === 'DELETE') {
        let params = { ids: this.checkedIds };

        this.$store
          .dispatch(this.model.types.batchDelete.action, params)
          .then((data) => {
            let { success, fail } = data;
            if (success > 0) {
              this.$toast.success(`${success}건이 일괄정산 삭제 되었습니다.`);
            }
            if (fail > 0) {
              this.$toast.error(`${fail}건이 정산삭제 되지 못했습니다.`);
            }
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          });
      } else if (command === 'SETTLE') {
        let params = { ids: this.checkedIds };

        this.$store
          .dispatch(this.model.types.batchComplete.action, params)
          .then((data) => {
            let { success, fail } = data;
            if (success > 0) {
              this.$toast.success(`${success}건이 일괄정산 완료 되었습니다.`);
            }
            if (fail > 0) {
              this.$toast.error(`${fail}건이 정산완료 되지 못했습니다.`);
            }
            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          });
      } else if (command === 'CANCEL') {
        let params = { ids: this.checkedIds };

        this.$store
          .dispatch(this.model.types.batchCancel.action, params)
          .then((data) => {
            let { success, fail } = data;
            if (success > 0) {
              this.$toast.success(`${success}건이 일괄정산 취소 되었습니다.`);
            }
            if (fail > 0) {
              this.$toast.error(`${fail}건이 정산취소 되지 못했습니다.`);
            }

            this.loadData();
          })
          .catch((e) => {
            this.$toast.error(e.message);
          });
      } else if (command === 'MAIL') {
        // 일괄 메일 전송
        this.$dialog
          .confirm(`정산서를 발송할까요?`, {
            okText: 'Confirm',
            cancelText: 'Cancel',
          })
          .then((dialog) => {
            this.sendBatchMail();
          })
          .catch(() => {});
      } else if (command === 'COMPLETE') {
        // 일괄 정산완료 알림톡 전송
        this.$dialog
          .confirm(`정산 완료 알림톡을 발송할까요?`, {
            okText: 'Confirm',
            cancelText: 'Cancel',
          })
          .then((dialog) => {
            this.sendBatchNotify('complete');
          })
          .catch(() => {});
      } else if (command === 'SETTLEMENT') {
        // 일괄 정산서 생성 알림톡 전송
        this.$dialog
          .confirm(`정산서 생성 알림톡을 발송할까요?`, {
            okText: 'Confirm',
            cancelText: 'Cancel',
          })
          .then((dialog) => {
            this.sendBatchNotify('settlement');
          })
          .catch(() => {});
      }
    },

    sendBatchMail() {
      // 요청에 필요한  정보를 params에 담아서
      let params = { ids: this.checkedIds };
      // 서버로 요청을 보냄
      this.$store
        .dispatch(this.model.types.batchSend.action, params)
        .then((data) => {
          this.$toast.success(`${data.success_count}/${data.count} 건의 메일이 발송되었습니다.`);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    sendBatchNotify(command) {
      // 요청에 필요한  정보를 params에 담아서
      let params = { ids: this.checkedIds, type: command };
      // 서버로 요청을 보냄
      this.$store
        .dispatch(this.model.types.batchSend.action, params)
        .then((data) => {
          this.$toast.success(`${data.success_count}/${data.count} 건의 알림톡이 발송되었습니다.`);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onClickCheckedAll(e) {
      if (e.value === 'all') {
        let checked = {};
        this._.forEach(this.items, (item) => {
          checked[item.uid] = true;
        });
        this.$data.checked = checked;
      } else if (e.value === undefined) {
        let checked = {};
        this._.forEach(this.items, (item) => {
          checked[item.uid] = false;
        });
        this.$data.checked = checked;
      }
    },

    onClickCheck(e) {
      setTimeout(() => {
        let hasChecked = false,
          hasUnchecked = false;

        this._.forEach(this.checked, (flag) => {
          if (flag) {
            hasChecked = true;
          } else {
            hasUnchecked = true;
          }
        });

        if (hasChecked === true && hasUnchecked === false) {
          this.$data.checkedAll = 'all';
        } else if (hasChecked === true && hasUnchecked === true) {
          this.$data.checkedAll = 'half';
        } else if (hasChecked === false && hasUnchecked === true) {
          this.$data.checkedAll = 'none';
        }
      }, 0);
    },

    onClickCreate() {
      this.$router.push({
        name: this.model.views.create.name,
        params: {},
      });
    },

    onClickView(row) {
      this.$router.push({
        name: this.model.views.view.name,
        params: this.model.views.view.params(row),
      });
    },

    onClickEdit(row) {
      this.$router.push({
        name: this.model.views.edit.name,
        params: this.model.views.edit.params(row),
      });
    },

    onClickDelete(row) {
      this.$dialog
        .confirm(this.getDeleteMessage(row), {
          okText: 'Delete',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onDelete(row);
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    onDelete(row) {
      if (!(this.model.types.delete && this.model.types.delete.action)) {
        this.$toast.error('Delete Action 이 정의되지 않았습니다.');
        return;
      }

      this.$store
        .dispatch(this.model.types.delete.action, row)
        .then((data) => {
          this.$toast.success('삭제되었습니다.');
          this.loadData();
        })
        .catch((e) => {
          this.$toast.error(e.message);
        });
    },

    fieldValue(field, row) {
      if (field.type === 'date' || field.type === 'datetime') {
        let dateValue = field.alias ? this.$utils.value(row, field.alias) : row[field.key];
        let date = new Date(dateValue);
        if (!date) {
          return 'Invalid Date';
        }
        return this.$moment(date).format(field.format);
      }

      if (this._.isString(field.alias)) {
        return this.$utils.value(row, field.alias);
      } else if (this._.isFunction(field.alias)) {
        return field.alias(field, row);
      }

      let value = row[field.key];
      if (typeof field.locale === 'function') {
        return field.locale(value);
      }

      return value;
    },

    toggleUtil(action) {
      if (action === 'excel') {
        this.$data.showExport = !this.showExport;
      }
    },
  },
  components: {
    Pills,
    SearchInput,
    Pagination,
    FilterValue,
  },
};
</script>
